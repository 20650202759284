/* eslint-disable react/destructuring-assignment */
// @ts-nocheck
import ErrorPage from '@/pages/403'
import React from 'react'

class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // 更新 state 以至于下一个渲染会显示回退UI
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // 你也可以将错误日志上报给服务器
    console.error('Error caught by ErrorBoundary: ', error, errorInfo)

    if (this.props.onError) {
      this.props.onError(error, errorInfo)
    }
  }

  render() {
    if (this.state.hasError) {
      // 你可以自定义回退UI
      return <ErrorPage />
    }

    return this.props.children
  }
}

export const withErrorBoundary = (Component) => {
  return function WrappedComponent(props) {
    return (
      <ErrorBoundary
        onError={() => {
          console.error('Error component name:', Component.name)
        }}
      >
        <Component {...props} />
      </ErrorBoundary>
    )
  }
}

export default ErrorBoundary
