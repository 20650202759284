import React from 'react'
import { useRouter } from 'next/router'
import { Card, Result } from 'antd'
import Button from '@/components/Button'

const ErrorPage = () => {
  const router = useRouter()
  return (
    <div className="flex h-full items-center justify-center">
      <Card>
        <Result
          status="403"
          title="403"
          subTitle={
            <div>
              <div>灵缇发现这个页面有问题，请联系灵缇工作人员提供帮助哦</div>
              <div>您可以联系商务总监:15370036553</div>
            </div>
          }
          extra={
            <div>
              <Button type="primary" onClick={() => router.push('/')}>
                返回首页
              </Button>
            </div>
          }
        />
      </Card>
    </div>
  )
}

export default ErrorPage
